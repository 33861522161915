<script lang="ts" setup>
defineProps({
    content: {
        type: Object as () => Section,
        required: true,
    },
});
const activeTabIndex = ref(0);

const changeActiveTab = (index: number) => {
    activeTabIndex.value = index;
};
</script>

<template>
    <div class="relative py-12 bg-white">
        <div class="container px-8 grid lg:grid-cols-12 gap-12 items-start pb-8">
            <div class="lg:col-span-6 -intro-x">
                <NuxtImg :alt="content.name" :src="content.imageUrl" :title="content.name" class="w-full object-contain rounded-xl hover:scale-105 ease-in-out duration-300" />
            </div>
            <div class="lg:col-span-6 space-y-3 intro-x">
                <div>
                    <div class="section-post-title text-primary" v-html="content.postTitle" />
                    <div class="section-title" v-html="content.title" />
                </div>
                <div class="section-description" v-html="content.description" />
                <div>
                    <ul class="mt-5 flex flex-col gap-2">
                        <li v-for="(item, index) in content.items" :key="item.id">
                            <div class="text-xl font-semibold flex items-center gap-5 py-2 border-y w-full cursor-pointer" @click="changeActiveTab(index)">
                                <Icon class="size-5 text-primary" name="ph:plus-bold" />
                                {{ item.name }}
                            </div>
                            <TransitionExpand>
                                <div v-if="activeTabIndex === index" class="my-3 text-xl font-normal">
                                    {{ item.description }}
                                </div>
                            </TransitionExpand>
                        </li>
                    </ul>
                </div>
                <div class="flex items-center gap-5">
                    <div v-if="content.buttonOneActive">
                        <NuxtLink :target="content.buttonOne.target" :to="content.buttonOne.url">
                            <button :class="['px-6 btn btn-' + content.buttonOne.style]">{{ content.buttonOne.label }}</button>
                        </NuxtLink>
                    </div>
                    <div v-if="content.buttonTwoActive">
                        <NuxtLink :target="content.buttonTwo.target" :to="content.buttonTwo.url">
                            <button :class="['px-6 btn btn-' + content.buttonTwo.style]">
                                <Icon v-if="content.buttonTwo.icon" :name="content.buttonTwo.icon" class="w-5 h-5 mr-2" />
                                <span>{{ content.buttonTwo.label }}</span>
                            </button>
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </div>
        <div class="divider">
            <svg data-name="Arrow Down Divider" preserveAspectRatio="none" viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg">
                <path class="fill-slate-100" d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z" />
            </svg>
        </div>
    </div>
</template>
<style scoped>
.divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.divider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
}
</style>
